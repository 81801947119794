'use client';
import {
	Box,
	ButtonGroup,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IRecipients } from '@negotium/models';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RecipientsWidget from '../RecipientsWidget';
import DeleteButton from '../DeleteButton';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
export default function RecipientsCardShow({
	lng = 'es',
	recipient,
	onCallBack,
}: {
	recipient: IRecipients;
	onCallBack: () => void;
	lng: 'es' | 'en';
}) {
	const { t } = useClientTranslation(lng, ['recipients']);
	const deleteElement = () => {};

	return (
		<ListItem
			sx={{
				marginBottom: 2,
				padding: 2,
				borderRadius: 5,
				backgroundColor: '#f9f9f9',
				border: '1px solid',
				borderColor: 'divider',
				boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
			}}
		>
			<Box
				sx={{
					width: '100%',
				}}
			>
				<ListItemText
					id={recipient.recipientId}
					primary={recipient.fullName}
					primaryTypographyProps={{
						variant: 'BodySection',
						fontSize: '1rem',
						fontWeight: '600',
					}}
					secondary={
						<Box display='flex' alignItems='center' ml={3} mt={2}>
							<LocationOnRoundedIcon color='secondary' />
							<Typography variant='ExplicationText' marginLeft={1}>
								{`${recipient.address}, ${recipient.municipality}, ${recipient.province}`}
							</Typography>
						</Box>
					}
				/>{' '}
				<ListItemText
					secondary={
						<Box display='flex' alignItems='center' ml={3}>
							<EmailIcon color='secondary' />
							<Typography variant='ExplicationText' marginLeft={1}>
								{recipient.email}
							</Typography>
						</Box>
					}
				/>
				<ListItemText
					sx={{
						width: '100%',
					}}
					secondary={
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
						>
							<Box display='flex' alignItems='center' ml={3}>
								<LocalPhoneIcon color='secondary' />
								<Typography variant='ExplicationText' marginLeft={1}>
									{recipient.phone}
								</Typography>
							</Box>

							<ButtonGroup>
								<RecipientsWidget
									type='Edit'
									id={recipient.recipientId}
									lng={lng}
									onCallBack={onCallBack}
								/>
								<DeleteButton
									onCallback={() => deleteElement()}
									isDisabled={false}
									alertMessage={t('confirmationCarriersDeleteMessage')}
									lng={lng}
								/>
							</ButtonGroup>
						</Box>
					}
				/>
			</Box>
		</ListItem>
	);
}
