import { useClientTranslation } from '@negotium/common';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

export interface IChangePassword {
	email: string;
	code: string;
	password: string;
	rePassword?: string;
}
const useChangePassword = (
	initialValues: IChangePassword,
	onSubmit: (values: IChangePassword) => void,
	lng: string
): {
	formik: FormikProps<IChangePassword>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		code: yup.string().required(t('codeRequired')),
		email: yup.string().email(t('emailInvalid')).required(t('emailRequired')),
		password: yup
			.string()
			.min(8, t('passwordCharacters'))
			.required(t('passwordRequired')),

		rePassword: yup
			.string()
			.required(t('rePasswordRequired'))
			.test('passwords-match', t('passwordNotMatch'), function (value) {
				return this.parent.password === value;
			}),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: IChangePassword) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useChangePassword;
