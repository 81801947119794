'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNotification } from '../ErrorContext';
import { Key, Logout } from '@mui/icons-material';
import { usePathname, useRouter } from 'next/navigation';
import {
	BottomNavigation,
	BottomNavigationAction,
	ListItem,
	Typography,
} from '@mui/material';
import Link from 'next/link';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { signOut } from '@negotium/libCustomer';

// TODO movil ponerlo fixed al bottom
export default function MenuProfile({ lng }: { lng: 'es' | 'en' }) {
	const { t } = useClientTranslation(lng, ['common']);
	const router = useRouter();
	const pathname = usePathname();

	const user = useSessionClient();
	const { setErrorNotification, setSuccessNotification } = useNotification();

	const handleLogout = async () => {
		signOut()
			.then(() => {
				setSuccessNotification(t('sessionClosedSuccessfully'));
				router.push(`/${lng}`);
			})
			.catch(() => {
				setErrorNotification(t('sessionClosedError'));
			});
	};

	const data: Array<{
		label: string;
		icon: JSX.Element;
		isLink: boolean;
		path?: string;
		pathCheck?: string;
		handlerClick?: () => void;
		validatePermission?: boolean;
	}> = [
		{
			label: 'profile',
			icon: <ManageAccountsIcon fontSize='small' color='secondary' />,
			isLink: true,
			path: '/account',
			pathCheck: `/${lng}/account`,
		},
		{
			label: 'wishList',
			icon: <FavoriteIcon fontSize='small' color='secondary' />,
			isLink: true,
			path: '/account/wishList',
			pathCheck: `/${lng}/account/wishList`,
		},
		{
			label: 'myRecipients',
			icon: <Diversity1Icon fontSize='small' color='secondary' />,
			isLink: true,
			path: '/account/myRecipients',
			pathCheck: `/${lng}/account/myRecipients`,
		},
		{
			label: 'orderHistory',
			icon: <ShoppingCartIcon fontSize='small' color='secondary' />,
			isLink: true,
			path: '/account/orderHistory',
			pathCheck: `/${lng}/account/orderHistory`,
		},

		{
			label: 'changePassword',
			icon: <Key fontSize='small' color='secondary' />,
			isLink: true,
			path: '/account/changePassword',
			pathCheck: `/${lng}/account/changePassword`,
		},

		{
			label: 'logout',
			icon: <Logout fontSize='small' color='secondary' />,
			isLink: false,
			handlerClick: handleLogout,
		},
	];

	return (
		<>
			<Box sx={{ display: { md: 'flex', xs: 'none' } }}>
				<Paper
					elevation={3}
					sx={{
						width: '100%',
						borderRadius: 3,
						overflow: 'hidden',
					}}
				>
					<List component='nav' disablePadding>
						<ListItem
							sx={{
								py: 2,
								pl: 5,

								display: 'flex',
								alignItems: 'center',
							}}
						>
							<ListItemIcon>
								<AccountCircleIcon fontSize='large' />
							</ListItemIcon>
							<ListItemText
								primary={`${t('hello')}, ${user?.name} ${user?.lastName}`}
								primaryTypographyProps={{
									variant: 'ExplicationTextBold',
									fontSize: '14px',
								}}
							/>
						</ListItem>
					</List>
					<Divider />
					<List
						sx={{
							background: (theme) => theme.palette.white?.main || '',
						}}
					>
						{data.map((item) => {
							return item.isLink ? (
								<Link
									key={item.label}
									href={item.path || ''}
									style={{
										textDecoration: 'none',
									}}
								>
									<ListItem
										key={item.label}
										title={t(item.label)}
										sx={{
											pl: 5,

											backgroundColor: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'secondary' : 'white'
												]?.main || '',
											color: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'white' : 'secondary'
												]?.main || '',
											svg: {
												stroke: (theme) =>
													theme.palette[
														item.pathCheck === pathname ? 'white' : 'secondary'
													]?.main,
												fill: (theme) =>
													theme.palette[
														item.pathCheck === pathname ? 'white' : 'secondary'
													]?.main,
											},

											':hover': {
												backgroundColor: (theme) => theme.palette.primary.main,
												color: (theme) => theme.palette.white?.main || '',
												svg: {
													stroke: (theme) => theme.palette.secondary.main,
													fill: (theme) => theme.palette.white?.main || '',
												},
											},
										}}
									>
										<ListItemIcon
											sx={{
												color:
													item.pathCheck === pathname
														? 'white !important'
														: 'black',
												svg: {
													fill: (theme) => theme.palette.secondary.main,
												},
											}}
										>
											{item.icon}
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography
													variant='ExplicationTextBold'
													textTransform='initial'
													margin='0'
													sx={{
														color: (theme) =>
															theme.palette[
																item.pathCheck === pathname
																	? 'white'
																	: 'secondary'
															]?.main || '',
													}}
												>
													{t(item.label)}
												</Typography>
											}
										/>
									</ListItem>
								</Link>
							) : (
								<ListItem
									key={item.label}
									title={t(item.label)}
									onClick={item.handlerClick}
									sx={{
										cursor: 'pointer',
										pl: 5,
										backgroundColor: (theme) =>
											theme.palette[
												item.pathCheck === pathname ? 'secondary' : 'white'
											]?.main || '',
										color: (theme) =>
											theme.palette[
												item.pathCheck === pathname ? 'white' : 'secondary'
											]?.main || '',
										svg: {
											stroke: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'white' : 'secondary'
												]?.main,
											fill: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'white' : 'secondary'
												]?.main,
										},

										':hover': {
											backgroundColor: (theme) => theme.palette.primary.main,
											color: (theme) => theme.palette.secondary.main,
											svg: {
												stroke: (theme) => theme.palette.secondary.main,
												fill: (theme) => theme.palette.secondary.main,
											},
										},
									}}
								>
									<ListItemIcon
										sx={{
											color:
												item.pathCheck === pathname
													? 'white !important'
													: 'black',
											svg: {
												fill: (theme) => theme.palette.secondary.main,
											},
											':hover': {
												color: 'white !important',
												svg: {
													fill: (theme) => theme.palette.secondary.main,
												},
											},
										}}
									>
										{item.icon}
									</ListItemIcon>
									<ListItemText
										primary={
											<Typography
												variant='ExplicationTextBold'
												textTransform='initial'
												margin='0'
												sx={{
													color: (theme) =>
														theme.palette[
															item.pathCheck === pathname
																? 'white'
																: 'secondary'
														]?.main || '',
													':hover': {
														color: (theme) =>
															theme.palette[
																item.pathCheck === pathname
																	? 'primary'
																	: 'secondary'
															].main,
													},
												}}
											>
												{t(item.label)}
											</Typography>
										}
									/>
								</ListItem>
							);
						})}
					</List>
				</Paper>
			</Box>
			<Paper
				sx={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					width: '100%',
					display: { md: 'none', xs: 'block' },
				}}
				elevation={4}
			>
				<BottomNavigation
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
					// value={value}
					// onChange={(event, newValue) => {
					//   setValue(newValue);
					// }}
				>
					{data.map((item) => {
						return (
							<BottomNavigationAction
								key={item.label}
								icon={item.icon}
								sx={{
									minWidth: `${100 / data.length}%`,
								}}
							/>
						);
					})}
				</BottomNavigation>
			</Paper>
		</>
	);
}
