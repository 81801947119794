import Notification from './Notification';
import Navbar from './Navbar';
import MenuProfile from './MenuProfile';
import Profile from './Profile';
import RecipientsCardShow from './RecipientsCardShow';
import RecipientsWidget from './RecipientsWidget';
import DeleteButton from './DeleteButton';
import RecipientsProfile from './RecipientsProfile';
import ChangePasswordPage from './ChangePasswordPage';
import FilterCategoryProduct from './FilterCategoryProduct';
import ImageCover from './ImageCover';
import WishList from './WishList';
import OrderHistory from './OrderHistory';
import { SectionPopularProducts } from './SectionPopularProducts';
import { SectionCategories } from './SectionCategories';
import { SectionDiscountedProducts } from './SectionDiscountedProducts';
import { SectionRecentProducts } from './SectionRecentProducts';
import { ChangePasswordDialog } from './ChangePassword';
import { ActivateAccount } from './ActivateAccount';
import { ForgetPassword } from './ForgetPassword';
import { Login } from './Login';
import { Register } from './Register';

import { LoadingSideBar, LoadingTable, LoadingRectangle } from './Skeleton';
import { useNotification, NotificationProvider } from './ErrorContext';
import ArrowSlider from './ArrowSlider';
import CardCategory from './CardCategory';
import CardAdvertising from './CardCategory copy';
import { CardProduct } from './CardProduct';
import { CardProductHorizontal } from './CardProductHorizontal';
import { Hero } from './Hero';
import { Advertising } from './Advertising';
import { ProductDetails } from './ProductDetails';
import { ButtonAddProductCardShop } from './ButtonAddProductCardShop';
import { ModalRequestWholesaler } from './ModalRequestWholesaler';

export {
	Navbar,
	Notification,
	useNotification,
	NotificationProvider,
	ActivateAccount,
	ForgetPassword,
	Login,
	Register,
	MenuProfile,
	ChangePasswordDialog,
	Profile,
	LoadingSideBar,
	LoadingTable,
	LoadingRectangle,
	RecipientsCardShow,
	RecipientsWidget,
	DeleteButton,
	RecipientsProfile,
	ChangePasswordPage,
	OrderHistory,
	FilterCategoryProduct,
	WishList,
	SectionPopularProducts,
	SectionCategories,
	ArrowSlider,
	CardCategory,
	SectionRecentProducts,
	CardProduct,
	Hero,
	Advertising,
	ProductDetails,
	CardProductHorizontal,
	SectionDiscountedProducts,
	ImageCover,
	CardAdvertising,
	ButtonAddProductCardShop,
	ModalRequestWholesaler,
};
