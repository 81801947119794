'use client';
import React, { MouseEvent, useState } from 'react';
import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import { useClientTranslation } from '@negotium/common';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

type ButtonAddProductCardShopProps = {
	lng: 'es' | 'en';
	justifyContent?: 'center' | 'space-between' | 'flex-end' | 'flex-start';
	onCallback: (value: number) => void;
};
export const ButtonAddProductCardShop = ({
	lng,
	onCallback,
	justifyContent = 'center',
}: ButtonAddProductCardShopProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);
	const [amount, setAmount] = useState<number>(1);

	const handleIncrement = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setAmount(amount + 1);
	};

	const handleDecrement = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (amount > 1) {
			setAmount(amount - 1);
		}
	};
	function handleSave(e: MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		e.stopPropagation();
		onCallback(amount);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: justifyContent,
				alignItems: 'center',
				width: '100%',
			}}
		>
			<ButtonGroup
				sx={{
					height: '30px',
				}}
				variant='text'
				aria-label={t('changeAmountOfProduct')}
				title={t('changeAmountOfProduct')}
			>
				<Button
					onClick={handleDecrement}
					aria-label={t('decrementAmount')}
					title={t('decrementAmount')}
					disabled={amount === 1}
					color='primary'
					variant='outlined'
					sx={{
						width: '20%',
					}}
				>
					-
				</Button>
				<TextField
					type='number'
					value={amount}
					inputProps={{
						sx: {
							minHeight: '30px',
							py: 0,
							px: 1,
							borderRadius: 0,
							textAlign: 'center',
							justifyContent: 'center',
							display: 'flex',
							'-moz-appearance': 'textfield',
							'::-webkit-outer-spin-button': {
								'-webkit-appearance': 'none',
								margin: 0,
							},
							'::-webkit-inner-spin-button': {
								'-webkit-appearance': 'none',
								margin: 0,
							},
						},
					}}
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setAmount(Number(e.target.value));
					}}
					sx={{
						width: '20%',

						borderRadius: 0,
						'&>*': {
							'&.MuiOutlinedInput-root': {
								borderRadius: 0,
							},
							fieldset: {
								borderColor: (theme) => theme.palette.primary.main,
							},
						},
					}}
				/>
				<Button
					onClick={handleIncrement}
					aria-label={t('incrementAmount')}
					title={t('incrementAmount')}
					sx={{
						width: '20%',
					}}
					color='primary'
					variant='outlined'
				>
					+
				</Button>
				<Button
					aria-label={t('addCartShop')}
					title={t('addCartShop')}
					variant='ButtonAcceptDialog'
					sx={{
						width: '40%',
					}}
					onClick={handleSave}
					startIcon={<AddShoppingCartIcon fontSize='large' />}
				>
					{t('add')}
				</Button>
			</ButtonGroup>
		</Box>
	);
};
