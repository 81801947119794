'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Button,
	Dialog,
	DialogContent,
	FormGroup,
	Grid,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	Box,
	InputLabel,
	DialogTitle,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import { LoadingButton } from '@mui/lab';
import useForgotFormData, { IForgetPassword } from './hooks/useForgotFormData';
import { forgotPassword } from '@negotium/libCustomer';
import Image from 'next/image';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type ForgetPasswordProps = {
	open: boolean;
	onClose: () => void;
	lng: 'es' | 'en';
	onOpenChangePassword: (email: string) => void;
	onOpenLogin: () => void;
};

export const ForgetPassword = ({
	open,
	onClose,
	lng = 'es',
	onOpenChangePassword,
	onOpenLogin,
}: ForgetPasswordProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [loadingAuth, setLoadingAuth] = useState(false);

	const initialValues = {
		email: '',
	};

	const onSubmit = async (values: IForgetPassword): Promise<void> => {
		setLoadingAuth(true);
		forgotPassword(values.email, lng)
			.then((result) => {
				if (result.status === 200) {
					setSuccessNotification(t('sendEmailForgetPassword'));
					setLoadingAuth(false);
					onOpenChangePassword(values.email);
				} else {
					setErrorNotification(t(result.message));
					setLoadingAuth(false);
				}
			})
			.catch((error) => {
				setErrorNotification(error);
			})
			.finally(() => {
				setLoadingAuth(false);
			});
	};

	const { formik, isLoading } = useForgotFormData(initialValues, onSubmit, lng);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			fullScreen={fullScreen}
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundImage: {
							md: `url('/static/desktopBgModal.jpg')`,
							xs: `url('/static/movilBgModal.jpg')`,
						},
						backgroundSize: 'cover',

						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			{' '}
			<DialogTitle>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						onClick={() => onClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container>
						<Grid item xs={12}>
							<Box sx={{ width: '10%', margin: 'auto' }}>
								<Image
									src='/static/favicon/128x128.png'
									width={100}
									height={100}
									alt={process.env.NEXT_PUBLIC_NAME || ''}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='Subtitle' textAlign='center' fontWeight=''>
								{t('recoverPassword')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant='ExplicationTextBold'
								my={3}
								textAlign='center'
							>
								{t('recoverPasswordDescription')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
									}}
								>
									{t('email')}
								</InputLabel>
								<TextField
									id='email'
									placeholder='Eg. johndoe@gmail.com'
									value={formik.values.email}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={
										formik.touched.email &&
										formik.errors.email &&
										t(String(formik.errors.email))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								marginTop: '20px',
							}}
							display='flex'
							justifyContent='space-evenly'
						>
							<Button
								autoFocus
								onClick={() => onOpenLogin()}
								variant='ButtonCancelDialog'
								title={t('back')}
							>
								{t('back')}
							</Button>
							<LoadingButton
								type='submit'
								fullWidth
								autoFocus
								loading={loadingAuth}
								title={t('recover')}
								disabled={isLoading || loadingAuth}
								variant='ButtonAcceptDialog'
							>
								{t('recover')}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
