'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IPortraitCreate } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getPortraitsActives } from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import Slider from 'react-slick';
// import dynamic from 'next/dynamic';

type HeroProps = {
	lng: 'es' | 'en';
};

// const ArrowSlider = dynamic(
// 	() =>
// 		import('@negotium/componentsCustomer').then((module) => module.ArrowSlider),
// 	{ ssr: false }
// );

export const Hero = ({ lng }: HeroProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const [portraits, setPortraits] = useState<Array<IPortraitCreate>>([]);

	const [loadingPortraits, setLoadingPortraits] = useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingPortraits) {
			getPortraitsActives()
				.then((response) => {
					setPortraits(response.docs);
					setLoadingPortraits(false);
				})
				.catch(() => {
					setLoadingPortraits(false);
					setErrorNotification(t('errorGetPortraits'));
				});
		}
	}, [loadingPortraits]);

	if (portraits.length === 0) {
		return null;
	}

	const settings = {
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		infinite: portraits.length > 1,
		speed: 5000,
		autoplaySpeed: 5000,
		autoplay: false,
		// nextArrow: <ArrowSlider right={true} />,
		// prevArrow: <ArrowSlider right={false} />,
	};

	return (
		<Container maxWidth={false} disableGutters>
			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={{
							position: 'relative',
							boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
						}}
					>
						<Slider {...settings}>
							{[...portraits].map((portrait) => {
								return (
									<Box key={portrait.portraitId} position='relative'>
										<Box
											width={{ xs: '100%', md: '100%' }}
											position='relative'
											display='flex'
											key={portrait.portraitId}
										>
											<ImageWithFallback
												src={portrait.image?.url || ''}
												fallbackImage='/static/facebook600x315.png'
												alt={portrait.title[lng]}
												width={portrait.image?.width}
												height={portrait.image?.height}
												style={{
													width: '100%',
													height: 'auto',
													display: 'flex',
												}}
											/>
										</Box>
										<Container
											maxWidth='xl'
											sx={{
												py: 3,
												background: 'white',
											}}
										>
											<Typography variant='TitleSection' my={2}>
												{portrait.title[lng]}
											</Typography>
											<Typography variant='ExplicationText' color='white.dark'>
												{portrait.description[lng]}
											</Typography>
										</Container>
									</Box>
								);
							})}
						</Slider>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};
