import { useClientTranslation } from '@negotium/common';
import { IUserChangePassword } from '@negotium/models';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

const useUserChangePass = (
	initialValues: IUserChangePassword,
	onSubmit: (values: IUserChangePassword) => void,
	lng: string
): {
	formik: FormikProps<IUserChangePassword>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		newPassword: yup
			.string()
			.min(8, t('passwordCharacters', { ns: 'validations' }))
			.required(t('fieldRequired')),
		oldPassword: yup
			.string()
			.min(8, t('passwordCharacters', { ns: 'validations' }))
			.required(t('fieldRequired')),
		rePassword: yup
			.string()
			.required(t('fieldRequired'))
			.test({
				name: 'passwords-match',
				message: t('passwordNotMatch'),
				test: function (value) {
					return this.parent.newPassword === value;
				},
			}),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: IUserChangePassword) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useUserChangePass;
