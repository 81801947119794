import { useClientTranslation } from '@negotium/common';
import { IPublicRegister } from '@negotium/models';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

const useRegisterFormData = (
	initialValues: IPublicRegister,
	onSubmit: (values: IPublicRegister) => void,
	lng: string
): {
	formik: FormikProps<IPublicRegister>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		userId: yup.string(),
		name: yup.string().required(t('fieldRequired')),
		lastName: yup.string().required(t('fieldRequired')),
		phone: yup.string(),
		email: yup.string().email(t('emailInvalid')).required(t('emailRequired')),
		password: yup
			.string()
			.min(8, t('passwordInvalid'))
			.required(t('passwordRequired')),
		rePassword: yup
			.string()
			.required(t('passwordRequired'))
			.test({
				name: 'passwords-match',
				message: t('passwordNotMatch'),
				test: function (value) {
					return this.parent.password === value;
				},
			}),

		ci: yup
			.string()
			.matches(/^\d{11}$/, t('ciInvalid'))
			.required(t('fieldRequired')),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: IPublicRegister) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useRegisterFormData;
