import { useClientTranslation } from '@negotium/common';
import { IWholesalerRequestSave } from '@negotium/models';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

const useWholesalerRequestFormData = (
	initialValues: IWholesalerRequestSave,
	onSubmit: (values: IWholesalerRequestSave) => void,
	lng: string
): {
	formik: FormikProps<IWholesalerRequestSave>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		companyName: yup.string().required(t('fieldRequired')),
		companyEmail: yup
			.string()
			.email(t('emailInvalid'))
			.required(t('fieldRequired')),
		companyPhone: yup.string().required(t('fieldRequired')),
		companyAddress: yup.string().required(t('fieldRequired')),
		deliveryLocation: yup.string().required(t('fieldRequired')),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: IWholesalerRequestSave) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useWholesalerRequestFormData;
