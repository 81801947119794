import { useClientTranslation } from '@negotium/common';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

export interface IForgetPassword {
	email: string;
}
const useForgotFormData = (
	initialValues: IForgetPassword,
	onSubmit: (values: IForgetPassword) => void,
	lng: string
): {
	formik: FormikProps<IForgetPassword>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		email: yup.string().email(t('emailInvalid')).required(t('emailRequired')),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: IForgetPassword) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useForgotFormData;
