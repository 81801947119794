import React from 'react';
import Imagen from 'next/image';
import { Container } from '@mui/material';

function ImageCover({
	id,
	url,
	alt,
	heightContainer,
	widthContainer,
	topLRounder,
	topRRounder,
	onclickProp,
	rounder,
	border,
	opacityC,
	withBlur,
	objectFit,
	cursor,
}: {
	id?: string;
	url?: string;
	alt?: string;
	heightContainer?: string;
	widthContainer?: string;
	topLRounder?: string;
	topRRounder?: string;
	onclickProp?: () => void;
	rounder?: string;
	border?: string;
	opacityC?: string;
	withBlur?: boolean;
	objectFit?: string;
	cursor?: string;
}) {
	const stylesContainer = {
		...(widthContainer ? { width: `${widthContainer}` } : { width: '100%' }),
		...(heightContainer
			? { height: `${heightContainer}` }
			: { height: '100%' }),
		...(border ? { border: `${border}` } : { border: 'none' }),
		...(cursor ? { cursor: `${cursor}` } : { cursor: 'default' }),
		...(rounder ? { borderRadius: `${rounder}` } : { borderRadius: '0px' }),

		...(topLRounder
			? { borderTopLeftRadius: `${topLRounder}` }
			: { borderTopLeftRadius: '0px' }),
		...(topRRounder
			? { borderTopRightRadius: `${topRRounder}` }
			: { borderTopRightRadius: '0px' }),
		overflow: 'hidden',
		position: 'relative',
	};

	const stylesCoverBlue = {
		width: '100%',
		height: '100%',
		position: 'absolute',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		filter: 'blur(20px)',
		backgroundImage: `url(${url})`,
		...(opacityC ? { opacity: `${opacityC}` } : { opacity: '0.8' }),
		backgroundBlendMode: 'saturation',
		...(rounder ? { borderRadius: `${rounder}` } : { borderRadius: '0px' }),
		...(topLRounder
			? { borderTopLeftRadius: `${topLRounder}` }
			: { borderTopLeftRadius: '0px' }),
		...(topRRounder
			? { borderTopRightRadius: `${topRRounder}` }
			: { borderTopRightRadius: '0px' }),

		...(border ? { border: `${border}` } : { border: 'none' }),
		overflow: 'hidden',
	};

	return (
		<Container sx={stylesContainer} maxWidth={false} disableGutters>
			{withBlur && (
				<Container sx={stylesCoverBlue} maxWidth={false} disableGutters />
			)}
			<Imagen
				style={{ objectPosition: 'center' }}
				id={id}
				src={url || ''}
				objectFit={objectFit}
				alt={alt || ''}
				fill
				onClick={() => onclickProp}
			/>
		</Container>
	);
}

export default ImageCover;
