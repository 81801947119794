'use client';
import { encryptAES, useClientTranslation } from '@negotium/common';
import {
	Box,
	Container,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useUserChangePass from './useUserChangePass';
import { LoadingButton } from '@mui/lab';
import { Key, Visibility, VisibilityOff } from '@mui/icons-material';
import { IUserChangePassword } from '@negotium/models';
import { useNotification } from '@negotium/componentsCustomer';
import { changePasswordAdminUser } from '@negotium/libCustomer';
import { useRouter } from 'next/navigation';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function ChangePasswordPage({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['users']);
	const router = useRouter();
	const [loading, setLoading] = useState<boolean>(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRePassword = () => setShowRePassword((show) => !show);
	const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { setErrorNotification, setSuccessNotification } = useNotification();

	const initialValues = {
		oldPassword: '',
		newPassword: '',
		rePassword: '',
	};

	const onSubmitForm = async (values: IUserChangePassword): Promise<void> => {
		setLoading(true);
		if (values.newPassword && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
			values.newPassword = encryptAES(
				values.newPassword,
				process.env.NEXT_PUBLIC_ENCRYPT_KEY
			);
		}

		changePasswordAdminUser(values.newPassword, values.oldPassword || '')
			.then(() => {
				setSuccessNotification(t('passwordChanged'));
				setLoading(false);
				router.push('/account');
			})
			.catch((err) => {
				setErrorNotification(err.message);
				setLoading(false);
			});
	};
	const { formik, isLoading } = useUserChangePass(
		initialValues,
		onSubmitForm,
		lng
	);

	return (
		<Grid container spacing={2}>
			<Grid item xs={8}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: 1,
					}}
				>
					<Key
						fontSize='large'
						color='secondary'
						sx={{ marginRight: '1rem' }}
					/>
					<Typography variant='TitleSection'>{t('changePassword')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: 4,
						borderRadius: 5,
					}}
				>
					<Paper
						elevation={2}
						sx={{
							backgroundColor: (theme) => theme.palette.white?.main || 'white',
							py: 5,
							borderRadius: 6,
						}}
					>
						<Container maxWidth='xl'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<form onSubmit={formik.handleSubmit}>
										<Grid
											container
											rowSpacing={2}
											columnSpacing={2}
											width={{
												md: '60%',
												xs: '90%',
											}}
											margin='auto'
										>
											<Grid item xs={12}>
												<FormGroup>
													<InputLabel
														sx={{
															color: (theme) =>
																theme.palette.secondary.main || 'black',
															fontWeight: '600',
															ml: 3,
															mb: 1,
														}}
													>
														{t('oldPassword')}
													</InputLabel>

													<OutlinedInput
														id='oldPassword'
														name='oldPassword'
														type={showOldPassword ? 'text' : 'password'}
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle oldPassword visibility'
																	onClick={handleClickShowOldPassword}
																	onMouseDown={handleMouseDownPassword}
																	edge='end'
																>
																	{showOldPassword ? (
																		<VisibilityOff color='secondary' />
																	) : (
																		<Visibility color='secondary' />
																	)}
																</IconButton>
															</InputAdornment>
														}
														color='primary'
														placeholder='********'
														value={formik.values.oldPassword}
														onChange={(e) => formik.handleChange(e)}
														onBlur={formik.handleBlur}
														error={
															formik.touched.oldPassword &&
															Boolean(formik.errors.oldPassword)
														}
														fullWidth={false}
														margin='dense'
														sx={{
															borderRadius: 4,
															'& .MuiOutlinedInput-notchedOutline': {
																borderColor: (theme) =>
																	theme.palette.primary.dark,
																borderWidth: 2,
															},
															'& .MuiInputBase-input': {
																pl: `25px !important`,
															},
															'& > *': {
																borderRadius: `16px !important`,
															},
														}}
													/>
													{formik.errors.oldPassword &&
														formik.touched.oldPassword && (
															<FormHelperText sx={{ color: '#d32f2f' }}>
																{t(formik.errors.oldPassword)}
															</FormHelperText>
														)}
												</FormGroup>
											</Grid>{' '}
											<Grid item xs={12}>
												<FormGroup>
													<InputLabel
														sx={{
															color: (theme) =>
																theme.palette.secondary.main || 'black',
															fontWeight: '600',
															ml: 3,
															mb: 1,
														}}
													>
														{t('newPassword')}
													</InputLabel>

													<OutlinedInput
														id='newPassword'
														name='newPassword'
														type={showPassword ? 'text' : 'password'}
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={handleClickShowPassword}
																	onMouseDown={handleMouseDownPassword}
																	edge='end'
																>
																	{showPassword ? (
																		<VisibilityOff color='secondary' />
																	) : (
																		<Visibility color='secondary' />
																	)}
																</IconButton>
															</InputAdornment>
														}
														color='primary'
														placeholder='********'
														value={formik.values.newPassword}
														onChange={(e) => formik.handleChange(e)}
														onBlur={formik.handleBlur}
														error={
															formik.touched.newPassword &&
															Boolean(formik.errors.newPassword)
														}
														fullWidth={false}
														margin='dense'
														sx={{
															borderRadius: 4,
															'& .MuiOutlinedInput-notchedOutline': {
																borderColor: (theme) =>
																	theme.palette.primary.dark,
																borderWidth: 2,
															},
															'& .MuiInputBase-input': {
																pl: `25px !important`,
															},
															'& > *': {
																borderRadius: `16px !important`,
															},
														}}
													/>
													{formik.errors.newPassword &&
														formik.touched.newPassword && (
															<FormHelperText sx={{ color: '#d32f2f' }}>
																{t(formik.errors.newPassword)}
															</FormHelperText>
														)}
												</FormGroup>
											</Grid>
											<Grid item xs={12}>
												<FormGroup>
													<InputLabel
														sx={{
															color: (theme) =>
																theme.palette.secondary.main || 'black',
															fontWeight: '600',
															ml: 3,
															mb: 1,
														}}
													>
														{t('rePassword')}
													</InputLabel>

													<OutlinedInput
														id='rePassword'
														label={t('rePassword')}
														type={showRePassword ? 'text' : 'password'}
														color='primary'
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={handleClickShowRePassword}
																	onMouseDown={handleMouseDownPassword}
																	edge='end'
																>
																	{showRePassword ? (
																		<VisibilityOff color='secondary' />
																	) : (
																		<Visibility color='secondary' />
																	)}
																</IconButton>
															</InputAdornment>
														}
														placeholder='********'
														value={formik.values.rePassword}
														onChange={(e) => formik.handleChange(e)}
														onBlur={formik.handleBlur}
														error={
															formik.touched.rePassword &&
															Boolean(formik.errors.rePassword)
														}
														fullWidth={false}
														margin='dense'
														sx={{
															borderRadius: 4,
															'& .MuiOutlinedInput-notchedOutline': {
																borderColor: (theme) =>
																	theme.palette.primary.dark,
																borderWidth: 2,
															},
															'& .MuiInputBase-input': {
																pl: `25px !important`,
															},
															'& > *': {
																borderRadius: `16px !important`,
															},
														}}
													/>
													{formik.errors.rePassword &&
														formik.touched.rePassword && (
															<FormHelperText sx={{ color: '#d32f2f' }}>
																{t(formik.errors.rePassword)}
															</FormHelperText>
														)}
												</FormGroup>
											</Grid>
											<Grid item xs={12} display='flex' justifyContent='center'>
												<LoadingButton
													fullWidth
													type='submit'
													autoFocus
													loading={isLoading || loading}
													disabled={isLoading || loading}
													loadingPosition='end'
													title={t('updated')}
													variant='ButtonAcceptDialog'
													sx={{
														mt: 1.5,
													}}
													aria-disabled={isLoading || loading}
												>
													{t('updated')}
												</LoadingButton>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</Paper>
				</Paper>
			</Grid>
		</Grid>
	);
}
