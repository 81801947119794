'use client';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClientTranslation } from '@negotium/common';

function DeleteButton({
	onCallback,
	title,
	lng,
	isDisabled,
	showOnlyIcon = false,
	alertMessage,
	icon,
	titleButton,
}: {
	onCallback: VoidFunction;
	title?: string;
	lng: string;
	isDisabled: boolean;
	showOnlyIcon?: boolean;
	alertMessage?: string;
	icon?: JSX.Element;
	titleButton?: string;
}) {
	const { t } = useClientTranslation(lng, ['common']);
	const [show, setShow] = useState(false);
	return (
		<>
			<Button
				variant='ButtonDelete'
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setShow(!show);
				}}
				title={title}
				disabled={isDisabled}
			>
				<Container
					sx={{ display: 'flex', justifyContent: 'center' }}
					maxWidth={false}
					disableGutters
				>
					{icon || <DeleteIcon />}
					{!showOnlyIcon && <Typography>{title}</Typography>}
				</Container>
			</Button>
			<Dialog
				fullScreen={false}
				open={show}
				onClose={() => {
					setShow(false);
				}}
				maxWidth='xs'
				fullWidth={false}
				PaperProps={{
					sx: {
						borderRadius: '12px',
						padding: '1rem',
					},
				}}
			>
				<DialogContent
					sx={{
						padding: '1rem',
					}}
				>
					<Typography
						variant='body1'
						className={'bodyAboutUsSection'}
						sx={{
							margin: '2rem 0 1rem 0',
						}}
					>
						{alertMessage ? alertMessage : t('confirmationDeleteMessage')}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						title={title}
						onClick={(e) => {
							e.stopPropagation();
							setShow(false);
						}}
						variant='contained'
						color='inherit'
					>
						{t('cancel')}
					</Button>
					<Button
						title={title}
						onClick={(e) => {
							e.stopPropagation();
							onCallback();
							setShow(false);
						}}
						color='error'
						variant='contained'
					>
						{titleButton || t('remove')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default DeleteButton;
