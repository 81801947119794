'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Alert,
	AlertTitle,
	Box,
	Container,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IProductCatalog } from '@negotium/models';
import {
	LoadingRectangle,
	useNotification,
} from '@negotium/componentsCustomer';
import { getWishList } from '@negotium/libCustomer';
import { CardProduct } from '../CardProduct';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function WishList({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['wishList']);

	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);
	const [productsCatalogs, setProductsCatalogs] = useState<
		Array<IProductCatalog> | []
	>([]);

	useEffect(() => {
		if (loading) {
			getWishList(10, 0)
				.then((result) => {
					setProductsCatalogs([...result.docs]);
					setLoading(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
					setLoading(false);
				});
		}
	}, []);
	function setWishListUserLogged(data: boolean, index: number) {
		const aux = productsCatalogs;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProductsCatalogs([...aux]);
	}

	if (loading) {
		return (
			<Container
				maxWidth={false}
				sx={{
					py: 4,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<LoadingRectangle width={'50%'} height='5vh' />
						</Grid>

						{[...Array(3).keys()].map((item) => (
							<Grid item xs={12} sm={4} md={3} key={item}>
								<LoadingRectangle width={'100%'} height='40vh' />
							</Grid>
						))}
					</Grid>
				</Container>
			</Container>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FavoriteIcon
						fontSize='large'
						color='secondary'
						sx={{ marginRight: '1rem' }}
					/>
					<Typography variant='TitleSection'>{t('wishList')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: 4,
						borderRadius: 5,
						height: '70vh',
						overflow: 'auto',
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container>
							{productsCatalogs.length === 0 && !loading && (
								<Grid item xs={12}>
									<Alert
										sx={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'column',
											svg: { width: '3rem', height: '3rem' },
										}}
										title='test'
										color='info'
										severity='info'
									>
										<AlertTitle
											sx={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											{t('noElement')}
										</AlertTitle>
									</Alert>
								</Grid>
							)}
							{productsCatalogs.length > 0 &&
								!loading &&
								productsCatalogs.map((item, index) => (
									<Grid item xs={12} sm={4} md={3} key={item.productId}>
										<CardProduct
											product={item}
											lng={lng}
											setWishListUserLogged={(data) =>
												setWishListUserLogged(data, index)
											}
										/>
									</Grid>
								))}
						</Grid>
					</Container>
				</Paper>
			</Grid>
		</Grid>
	);
}
