'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Box,
	Container,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { IOrderShow } from '@negotium/models';
import {
	LoadingRectangle,
	useNotification,
} from '@negotium/componentsCustomer';
import { getPaidOrdersByAuthUser } from '@negotium/libCustomer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import Link from 'next/link';
import dayjs from 'dayjs';

type OrderHistoryProps = {
	lng: 'es' | 'en';
};

export default function OrderHistory({ lng }: OrderHistoryProps) {
	const { t } = useClientTranslation(lng, ['orders', 'common']);

	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);
	const [ordersHistory, setOrdersHistory] = useState<Array<IOrderShow>>([]);

	useEffect(() => {
		if (loading) {
			getPaidOrdersByAuthUser()
				.then((result) => {
					setOrdersHistory([...result.docs]);
					setLoading(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
					setLoading(false);
				});
		}
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: 1,
					}}
				>
					<ShoppingCartIcon
						fontSize='large'
						color='secondary'
						sx={{ marginRight: '1rem' }}
					/>
					<Typography variant='TitleSection'>{t('orderHistory')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: 4,
						borderRadius: 5,
						height: '70vh',
						overflow: 'auto',
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container spacing={2}>
							{loading &&
								[...Array(3).keys()].map((item) => (
									<Grid item xs={12} md={4} key={item}>
										<LoadingRectangle width={'100%'} />
									</Grid>
								))}

							{!loading && (
								<Grid item xs={12}>
									<TableContainer
										sx={{
											background: 'white',
											borderRadius: 5,
											height: 'calc(70vh - 64px)',
											overflow: 'auto',
										}}
									>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderNumber')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderDate')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderStatus')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderTotal')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('actions', { ns: 'common' })}
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{ordersHistory.length === 0 && (
													<TableRow
														sx={{
															background: '#f6f6f6',
														}}
													>
														<TableCell colSpan={5}>
															<Box
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	ml: 1,
																}}
															>
																<InfoIcon
																	fontSize='medium'
																	color='secondary'
																	sx={{ marginRight: '5px' }}
																/>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																>
																	{t('userAuthNotOrders')}
																</Typography>
															</Box>
														</TableCell>
													</TableRow>
												)}
												{ordersHistory.length > 0 &&
													ordersHistory.map((order) => (
														<TableRow key={order.orderId}>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{order.consecutiveNumber}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{dayjs(order.createdAt)
																		.locale(lng)
																		.format('DD, MMMM YYYY hh:mm a')}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{t(order.status)}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{formatCurrency(order.totalPrice)}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<IconButton color='info'>
																	<Link href={`/order/${order.orderId}`}>
																		<VisibilityIcon color='secondary' />
																	</Link>
																</IconButton>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							)}
						</Grid>
					</Container>
				</Paper>
			</Grid>
		</Grid>
	);
}
